import React from "react"
import Layout from "../components/layout"
import Group from "../components/Group"
import { Routing, getTitle, getId } from "../utils/routing"
import SEO from "../components/seo"
import ProjectName from "../components/ProjectName"
const title = Routing.ODDS_DEFINITIONS.title
const RC = Routing.ODDS_DEFINITIONS.sub

const OddsDefinitions = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>{title}</h1>
      <div className="grid">
        <Group id={getId(RC.DECIMAL_ODDS)}>
          <h2>{getTitle(RC.DECIMAL_ODDS)}</h2>
          <p>
            Decimal odds, also known as European odds since they are most
            commonly used in Europe, tell you the potential amount you can win
            including the bet amount. For example if you bet $10 at decimal odds
            of 1.5, the total return is $15 ($10 * 1.5), and the potential
            profit is $5 ($15 - original bet amount $10).
          </p>
        </Group>

        <Group id={getId(RC.DECIMAL_TO_FRACTIONAL)}>
          <h2>{getTitle(RC.DECIMAL_TO_FRACTIONAL)}</h2>
          <p>
            To convert decimal odds to fractional odds, substract 1 and convert
            to a fraction, then reduce to its simplest form.
            <br />
            <i className="example"> Example: 3.5 => 3.5 - 1 = 2.5/1 = 5/2 </i>
          </p>
        </Group>

        <Group id={getId(RC.DECIMAL_TO_AMERICAN)}>
          <h2>{getTitle(RC.DECIMAL_TO_AMERICAN)}</h2>
          <p>
            To convert decimal odds of 2.0 or higher, substract 1 and multiply
            by 100. <br />
            <i className="example">
              {" "}
              Example: 2.5 => 2.5 - 1 * 100 = +100
            </i>{" "}
            <br />
            To convert decimal odds of 2.0 or less, substract 1 and then divide
            -100 by the odds. <br />
            <i className="example">
              {" "}
              Example: 1.5 => -100/(1.5 - 1) = -100/0.5 = -200
            </i>
          </p>
        </Group>

        <Group id={getId(RC.FRACTIONAL_ODDS)}>
          <h2>{getTitle(RC.FRACTIONAL_ODDS)}</h2>
          <p>
            Fractional odds, also known as British or Traditional odds, are used
            mainly in the UK. They tell you the amount of profit relative to
            your stake if you win your bet. For example, if you bet $10 at odds
            of 3/1, you will receive $30 of profit if you win.
          </p>
        </Group>

        <Group id={getId(RC.FRACTIONAL_TO_DECIMAL)}>
          <h2>{getTitle(RC.FRACTIONAL_TO_DECIMAL)}</h2>
          <p>
            To convert fractional odds to decimal odds, convert the Fraction to
            a Decimal and add 1. <br />
            <i className="example">
              {" "}
              Example: 3/4 => (3/4) + 1 = 0.75 + 1 = 1.75
            </i>
          </p>
        </Group>

        <Group id={getId(RC.FRACTIONAL_TO_AMERICAN)}>
          <h2>{getTitle(RC.FRACTIONAL_TO_AMERICAN)}</h2>
          <p>
            To convert fractional odds that are over 1/1, convert the Fraction
            to Decimal and multiply by 100.
            <br />
            <i className="example">
              {" "}
              Example: 7/4 => (7/4) * 100 = 1.4 * 100 = +140
            </i>{" "}
            <br />
            To convert fractional odds that are under 1/1, divide -100 by the
            Fraction as a Decimal. <br />
            <i className="example">
              {" "}
              Example: 1/4 => -100/(1/4) = -100/0.25 = -400
            </i>
          </p>
        </Group>

        <Group id={getId(RC.AMERICAN_ODDS)}>
          <h2>{getTitle(RC.AMERICAN_ODDS)}</h2>
          <p>
            American odds, also known as Monelyline odds, are used most by US
            bookmakers. If the odds are positive, they tell you the amount you
            would win on a $100 bet. If the odds are negative, they tell you the
            amount you would need to bet to win $100.
          </p>
        </Group>

        <Group id={getId(RC.AMERICAN_TO_FRACTIONAL)}>
          <h2>{getTitle(RC.AMERICAN_TO_FRACTIONAL)}</h2>
          <p>
            To convert positive (+) odds, divide the American by 100 and then
            reduce to its simplest form. <br />
            <i className="example"> Example: +120 => (120/100) = 6/5 </i> <br />
            To convert negative (-) odds, divide 100 by the American and then
            reduce to its simplest form. <br />
            <i className="example"> Example: -120 => (100/120) = 5/6</i>
          </p>
        </Group>

        <Group id={getId(RC.AMERICAN_TO_DECIMAL)}>
          <h2>{getTitle(RC.AMERICAN_TO_DECIMAL)}</h2>
          <p>
            To convert positive (+) odds, divide the American by 100 and add 1.{" "}
            <br />
            <i className="example">
              {" "}
              Example: +100 => (100/100) + 1 = 1 + 1 = 2.0
            </i>{" "}
            <br />
            To convert negative (-) odds, divide 100 by the American, and add 1.{" "}
            <br />
            <i className="example">
              {" "}
              Example: -110 => -110/(100/110) + 1 = 1 + 0.9091 = 1.9091
            </i>
          </p>
        </Group>

        <Group id={getId(RC.IMPLIED_ODDS)}>
          <h2>{getTitle(RC.IMPLIED_ODDS)}</h2>
          <p>
            Implied probability in sports betting markets is simply a conversion
            of traditional odds into a percentage, but it also takes into
            account the house edge and eliminates it to express the odds as the
            “true odds” of an event occurring. <ProjectName /> uses Implied Odds
            converted into dollars and cents as pricing method for our markets.
          </p>
        </Group>
      </div>
      <div className="boxForTable">
        <Group id={getId(RC.ODDS_CONVERSION_CHART)}>
          <h2>{getTitle(RC.ODDS_CONVERSION_CHART)}</h2>
          <p>
            For a quick comparison, use this pre-calculated odds chart to look
            up Decimal, Fractional, American and Implied odds.
          </p>
        </Group>
        <table className="table_glossary">
          <thead>
            <tr>
              <th>Implied</th>
              <th>Decimal</th>
              <th>American</th>
              <th>Fractional</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="implied">0.01</td>
              <td className="decimal">100.00</td>
              <td className="american">+9900</td>
              <td className="fractional">99/1</td>
            </tr>
            <tr>
              <td className="implied">0.02</td>
              <td className="decimal">50.00</td>
              <td className="american">+4900</td>
              <td className="fractional">49/1</td>
            </tr>
            <tr>
              <td className="implied">0.03</td>
              <td className="decimal">33.33</td>
              <td className="american">+3233</td>
              <td className="fractional">97.3</td>
            </tr>
            <tr>
              <td className="implied">0.04</td>
              <td className="decimal">25.00</td>
              <td className="american">+2400</td>
              <td className="fractional">24/1</td>
            </tr>
            <tr>
              <td className="implied">0.05</td>
              <td className="decimal">20.00</td>
              <td className="american">+1900</td>
              <td className="fractional">19/1</td>
            </tr>
            <tr>
              <td className="implied">0.06</td>
              <td className="decimal">16.67</td>
              <td className="american">+1567</td>
              <td className="fractional">47/3</td>
            </tr>
            <tr>
              <td className="implied">0.07</td>
              <td className="decimal">14.29</td>
              <td className="american">+1329</td>
              <td className="fractional">93/7</td>
            </tr>
            <tr>
              <td className="implied">0.08</td>
              <td className="decimal">12.50</td>
              <td className="american">+1150</td>
              <td className="fractional">23/2</td>
            </tr>
            <tr>
              <td className="implied">0.09</td>
              <td className="decimal">11.11</td>
              <td className="american">+1011</td>
              <td className="fractional">91/9</td>
            </tr>
            <tr>
              <td className="implied">0.10</td>
              <td className="decimal">10.00</td>
              <td className="american">+900</td>
              <td className="fractional">9/1</td>
            </tr>
            <tr>
              <td className="implied">0.11</td>
              <td className="decimal">9.09</td>
              <td className="american">+809</td>
              <td className="fractional">89/11</td>
            </tr>
            <tr>
              <td className="implied">0.12</td>
              <td className="decimal">8.33</td>
              <td className="american">+733</td>
              <td className="fractional">22/3</td>
            </tr>
            <tr>
              <td className="implied">0.13</td>
              <td className="decimal">7.69</td>
              <td className="american">+669</td>
              <td className="fractional">87/13</td>
            </tr>
            <tr>
              <td className="implied">0.14</td>
              <td className="decimal">7.14</td>
              <td className="american">+614</td>
              <td className="fractional">47/3</td>
            </tr>
            <tr>
              <td className="implied">0.15</td>
              <td className="decimal">6.67</td>
              <td className="american">+567</td>
              <td className="fractional">17/3</td>
            </tr>
            <tr>
              <td className="implied">0.16</td>
              <td className="decimal">6.25</td>
              <td className="american">+525</td>
              <td className="fractional">21/4</td>
            </tr>
            <tr>
              <td className="implied">0.17</td>
              <td className="decimal">5.88</td>
              <td className="american">+488</td>
              <td className="fractional">83/17</td>
            </tr>
            <tr>
              <td className="implied">0.18</td>
              <td className="decimal">5.56</td>
              <td className="american">+456</td>
              <td className="fractional">41/9</td>
            </tr>
            <tr>
              <td className="implied">0.19</td>
              <td className="decimal">5.26</td>
              <td className="american">+426</td>
              <td className="fractional">81/19</td>
            </tr>
            <tr>
              <td className="implied">0.20</td>
              <td className="decimal">5.00</td>
              <td className="american">+400</td>
              <td className="fractional">4/1</td>
            </tr>
            <tr>
              <td className="implied">0.21</td>
              <td className="decimal">4.76</td>
              <td className="american">+376</td>
              <td className="fractional">79/21</td>
            </tr>
            <tr>
              <td className="implied">0.22</td>
              <td className="decimal">4.55</td>
              <td className="american">+355</td>
              <td className="fractional"> 38/11</td>
            </tr>
            <tr>
              <td className="implied">0.23</td>
              <td className="decimal">4.35</td>
              <td className="american">+335</td>
              <td className="fractional">77/23</td>
            </tr>
            <tr>
              <td className="implied">0.24</td>
              <td className="decimal">4.17</td>
              <td className="american">+317</td>
              <td className="fractional">19/6</td>
            </tr>
            <tr>
              <td className="implied">0.25</td>
              <td className="decimal">4.00</td>
              <td className="american">+300</td>
              <td className="fractional">3/1</td>
            </tr>
            <tr>
              <td className="implied">0.26</td>
              <td className="decimal">3.85</td>
              <td className="american">+285</td>
              <td className="fractional">37/13</td>
            </tr>
            <tr>
              <td className="implied">0.27</td>
              <td className="decimal">3.70</td>
              <td className="american">+270</td>
              <td className="fractional">73/27</td>
            </tr>
            <tr>
              <td className="implied">0.28</td>
              <td className="decimal">3.57</td>
              <td className="american">+257</td>
              <td className="fractional">18/7</td>
            </tr>
            <tr>
              <td className="implied">0.29</td>
              <td className="decimal">3.45</td>
              <td className="american">+245</td>
              <td className="fractional">71/29</td>
            </tr>
            <tr>
              <td className="implied">0.30</td>
              <td className="decimal">3.33</td>
              <td className="american">+233</td>
              <td className="fractional">7/3</td>
            </tr>
            <tr>
              <td className="implied">0.31</td>
              <td className="decimal">3.23</td>
              <td className="american">+223</td>
              <td className="fractional">69/31</td>
            </tr>
            <tr>
              <td className="implied">0.32</td>
              <td className="decimal">3.13</td>
              <td className="american">+213</td>
              <td className="fractional">17/8</td>
            </tr>
            <tr>
              <td className="implied">0.33</td>
              <td className="decimal">3.03</td>
              <td className="american">+203</td>
              <td className="fractional">67/33</td>
            </tr>
            <tr>
              <td className="implied">0.34</td>
              <td className="decimal">2.94</td>
              <td className="american">+194</td>
              <td className="fractional"> 33/17</td>
            </tr>
            <tr>
              <td className="implied">0.35</td>
              <td className="decimal">2.86</td>
              <td className="american">+186</td>
              <td className="fractional">13/7</td>
            </tr>
            <tr>
              <td className="implied">0.36</td>
              <td className="decimal">2.78</td>
              <td className="american">+178</td>
              <td className="fractional">16/9</td>
            </tr>
            <tr>
              <td className="implied">0.37</td>
              <td className="decimal">2.70</td>
              <td className="american">+170</td>
              <td className="fractional">63/37</td>
            </tr>
            <tr>
              <td className="implied">0.38</td>
              <td className="decimal">2.63</td>
              <td className="american">+163</td>
              <td className="fractional">31/19</td>
            </tr>
            <tr>
              <td className="implied">0.39</td>
              <td className="decimal">2.56</td>
              <td className="american">+156</td>
              <td className="fractional">61/39</td>
            </tr>
            <tr>
              <td className="implied">0.40</td>
              <td className="decimal">2.50</td>
              <td className="american">+150</td>
              <td className="fractional">3/2</td>
            </tr>
            <tr>
              <td className="implied">0.41</td>
              <td className="decimal">2.44</td>
              <td className="american">+144</td>
              <td className="fractional">59/41</td>
            </tr>
            <tr>
              <td className="implied">0.42</td>
              <td className="decimal">2.38</td>
              <td className="american">+138</td>
              <td className="fractional">29/21</td>
            </tr>
            <tr>
              <td className="implied">0.43</td>
              <td className="decimal">2.33</td>
              <td className="american">+133</td>
              <td className="fractional">57/43</td>
            </tr>
            <tr>
              <td className="implied">0.44</td>
              <td className="decimal">2.27</td>
              <td className="american">+127</td>
              <td className="fractional">14/11</td>
            </tr>
            <tr>
              <td className="implied">0.45</td>
              <td className="decimal">2.22</td>
              <td className="american">+122</td>
              <td className="fractional">11/9</td>
            </tr>
            <tr>
              <td className="implied">0.46</td>
              <td className="decimal">2.17</td>
              <td className="american">+117</td>
              <td className="fractional"> 27/23</td>
            </tr>
            <tr>
              <td className="implied">0.47</td>
              <td className="decimal">2.13</td>
              <td className="american">+113</td>
              <td className="fractional">53/47</td>
            </tr>
            <tr>
              <td className="implied">0.48</td>
              <td className="decimal">2.08</td>
              <td className="american">+108</td>
              <td className="fractional">13/12</td>
            </tr>
            <tr>
              <td className="implied">0.49</td>
              <td className="decimal">2.04</td>
              <td className="american">+104</td>
              <td className="fractional">51/49</td>
            </tr>
            <tr>
              <td className="implied">0.50</td>
              <td className="decimal">2.00</td>
              <td className="american">+100</td>
              <td className="fractional">1/1</td>
            </tr>
            <tr>
              <td className="implied">0.51</td>
              <td className="decimal">1.96</td>
              <td className="american">-104</td>
              <td className="fractional">49/51</td>
            </tr>
            <tr>
              <td className="implied">0.52</td>
              <td className="decimal">1.92</td>
              <td className="american">-108</td>
              <td className="fractional">12/13</td>
            </tr>
            <tr>
              <td className="implied">0.53</td>
              <td className="decimal">1.89</td>
              <td className="american">-113</td>
              <td className="fractional">47/53</td>
            </tr>
            <tr>
              <td className="implied">0.54</td>
              <td className="decimal">1.85</td>
              <td className="american">-117</td>
              <td className="fractional">23/27</td>
            </tr>
            <tr>
              <td className="implied">0.55</td>
              <td className="decimal">1.82</td>
              <td className="american">-122</td>
              <td className="fractional">9/11</td>
            </tr>
            <tr>
              <td className="implied">0.56</td>
              <td className="decimal">1.79</td>
              <td className="american">-127</td>
              <td className="fractional"> 11/14</td>
            </tr>
            <tr>
              <td className="implied">0.57</td>
              <td className="decimal">1.75</td>
              <td className="american">-133</td>
              <td className="fractional">43/57</td>
            </tr>
            <tr>
              <td className="implied">0.58</td>
              <td className="decimal">1.72</td>
              <td className="american">-138</td>
              <td className="fractional">21/29</td>
            </tr>
            <tr>
              <td className="implied">0.59</td>
              <td className="decimal">1.69</td>
              <td className="american">-144</td>
              <td className="fractional">41/59</td>
            </tr>
            <tr>
              <td className="implied">0.60</td>
              <td className="decimal">1.67</td>
              <td className="american">-150</td>
              <td className="fractional">2/3</td>
            </tr>
            <tr>
              <td className="implied">0.61</td>
              <td className="decimal">1.64</td>
              <td className="american">-156</td>
              <td className="fractional">39/61</td>
            </tr>
            <tr>
              <td className="implied">0.62</td>
              <td className="decimal">1.61</td>
              <td className="american">-163</td>
              <td className="fractional">19/31</td>
            </tr>
            <tr>
              <td className="implied">0.63</td>
              <td className="decimal">1.59</td>
              <td className="american">-170</td>
              <td className="fractional">37/63</td>
            </tr>
            <tr>
              <td className="implied">0.64</td>
              <td className="decimal">1.56</td>
              <td className="american">-178</td>
              <td className="fractional">16/9</td>
            </tr>
            <tr>
              <td className="implied">0.65</td>
              <td className="decimal">1.54</td>
              <td className="american">-186</td>
              <td className="fractional">7/13</td>
            </tr>
            <tr>
              <td className="implied">0.66</td>
              <td className="decimal">1.52</td>
              <td className="american">-194</td>
              <td className="fractional">17/33</td>
            </tr>
            <tr>
              <td className="implied">0.67</td>
              <td className="decimal">1.49</td>
              <td className="american">-203</td>
              <td className="fractional">33/67</td>
            </tr>
            <tr>
              <td className="implied">0.68</td>
              <td className="decimal">1.47</td>
              <td className="american">-213</td>
              <td className="fractional">8/17</td>
            </tr>
            <tr>
              <td className="implied">0.69</td>
              <td className="decimal">1.45</td>
              <td className="american">-223</td>
              <td className="fractional">31/69</td>
            </tr>
            <tr>
              <td className="implied">0.70</td>
              <td className="decimal">1.43</td>
              <td className="american">-233</td>
              <td className="fractional">3/7</td>
            </tr>
            <tr>
              <td className="implied">0.71</td>
              <td className="decimal">1.41</td>
              <td className="american">-245</td>
              <td className="fractional">29/71</td>
            </tr>
            <tr>
              <td className="implied">0.72</td>
              <td className="decimal">1.39</td>
              <td className="american">-257</td>
              <td className="fractional">7/18</td>
            </tr>
            <tr>
              <td className="implied">0.73</td>
              <td className="decimal">1.37</td>
              <td className="american">-270</td>
              <td className="fractional">27/73</td>
            </tr>
            <tr>
              <td className="implied">0.74</td>
              <td className="decimal">1.35</td>
              <td className="american">-285</td>
              <td className="fractional">13/37</td>
            </tr>
            <tr>
              <td className="implied">0.75</td>
              <td className="decimal">1.33</td>
              <td className="american">-300</td>
              <td className="fractional">1/3</td>
            </tr>
            <tr>
              <td className="implied">0.76</td>
              <td className="decimal">1.32</td>
              <td className="american">-317</td>
              <td className="fractional">6/19</td>
            </tr>
            <tr>
              <td className="implied">0.77</td>
              <td className="decimal">1.30</td>
              <td className="american">-335</td>
              <td className="fractional">23/77</td>
            </tr>
            <tr>
              <td className="implied">0.78</td>
              <td className="decimal">1.28</td>
              <td className="american">-355</td>
              <td className="fractional">11/38</td>
            </tr>
            <tr>
              <td className="implied">0.79</td>
              <td className="decimal">1.27</td>
              <td className="american">-376</td>
              <td className="fractional">21/79</td>
            </tr>
            <tr>
              <td className="implied">0.80</td>
              <td className="decimal">1.25</td>
              <td className="american">-400</td>
              <td className="fractional">1/4</td>
            </tr>
            <tr>
              <td className="implied">0.81</td>
              <td className="decimal">1.23</td>
              <td className="american">-426</td>
              <td className="fractional">19/81</td>
            </tr>
            <tr>
              <td className="implied">0.82</td>
              <td className="decimal">1.22</td>
              <td className="american">-456</td>
              <td className="fractional">9/41</td>
            </tr>
            <tr>
              <td className="implied">0.83</td>
              <td className="decimal">1.20</td>
              <td className="american">-488</td>
              <td className="fractional">17/83</td>
            </tr>
            <tr>
              <td className="implied">0.84</td>
              <td className="decimal">1.19</td>
              <td className="american">-525</td>
              <td className="fractional">4/21</td>
            </tr>
            <tr>
              <td className="implied">0.85</td>
              <td className="decimal">1.18</td>
              <td className="american">-567</td>
              <td className="fractional">3/17</td>
            </tr>
            <tr>
              <td className="implied">0.86</td>
              <td className="decimal">1.16</td>
              <td className="american">-614</td>
              <td className="fractional">7/43</td>
            </tr>
            <tr>
              <td className="implied">0.87</td>
              <td className="decimal">1.15</td>
              <td className="american">-669</td>
              <td className="fractional">13/87</td>
            </tr>
            <tr>
              <td className="implied">0.88</td>
              <td className="decimal">1.14</td>
              <td className="american">-733</td>
              <td className="fractional">3/22</td>
            </tr>
            <tr>
              <td className="implied">0.89</td>
              <td className="decimal">1.12</td>
              <td className="american">-809</td>
              <td className="fractional">11/89</td>
            </tr>
            <tr>
              <td className="implied">0.90</td>
              <td className="decimal">1.11</td>
              <td className="american">-900</td>
              <td className="fractional">1/9</td>
            </tr>
            <tr>
              <td className="implied">0.91</td>
              <td className="decimal">1.10</td>
              <td className="american">-1011</td>
              <td className="fractional">9/91</td>
            </tr>
            <tr>
              <td className="implied">0.92</td>
              <td className="decimal">1.09</td>
              <td className="american">-1150</td>
              <td className="fractional">2/32</td>
            </tr>
            <tr>
              <td className="implied">0.93</td>
              <td className="decimal">1.08</td>
              <td className="american">-1329</td>
              <td className="fractional">7/93</td>
            </tr>
            <tr>
              <td className="implied">0.94</td>
              <td className="decimal">1.06</td>
              <td className="american">-1567</td>
              <td className="fractional">3/47</td>
            </tr>
            <tr>
              <td className="implied">0.95</td>
              <td className="decimal">1.05</td>
              <td className="american">-1900</td>
              <td className="fractional">1/19</td>
            </tr>
            <tr>
              <td className="implied">0.96</td>
              <td className="decimal">1.04</td>
              <td className="american">-2400</td>
              <td className="fractional">1/24</td>
            </tr>
            <tr>
              <td className="implied">0.97</td>
              <td className="decimal">1.03</td>
              <td className="american">-3233</td>
              <td className="fractional">3/97</td>
            </tr>
            <tr>
              <td className="implied">0.98</td>
              <td className="decimal">1.02</td>
              <td className="american">-4900</td>
              <td className="fractional">1/49</td>
            </tr>
            <tr>
              <td className="implied">0.99</td>
              <td className="decimal">1.01</td>
              <td className="american">-9900</td>
              <td className="fractional">1/99</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default OddsDefinitions
